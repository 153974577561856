import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                brandLimeGreen: '#A8CF45 ',
                brandGreen: '#82A81A',
                brandPurple: '#261062',
                primary: '#82A81A',                 
            },
            light: {              
                brandLimeGreen: '#A8CF45 ',
                brandGreen: '#82A81A',
                brandPurple: '#261062',
                primary: '#82A81A',
            }     
        }, 
        options: {
            customProperties: true
        }    
    }        
});
