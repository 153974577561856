<template><div></div></template>

<script>
  export default {
    name: 'Home',
    mounted: function(){
      if(this.$route.name=='Home' || this.$route.matched.length==0){        
        let output = '/tools/kctrnt'

        let counter = 0
        for(let key in this.$route.query){
          let value = this.$route.query[key]
          if(counter==0){
            output+='?'
          }else{
            output+='&'
          }
          output+=key+'='+value
        }
        this.$router.push(output)
      }
    }
  }
</script>
